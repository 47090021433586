<template>
  <div
    v-if="getPublicListOrganisation && getPublicListOrganisation.length"
    class="row mb-5"
  >
    <div
      class="container"
      v-for="item in getPublicListOrganisation"
      :key="item.customer_name"
    >
      <div class="card">
        <div class="layer"></div>
        <div class="content">
          <div class="details">
            <h2>{{ item.customer_name }}<br /></h2>
            <CRow class="justify-content-center mt-4">
              <span
                v-for="organisation in item.organisation"
                :key="organisation.organisation_name"
                class="organisation-name"
              >
                <button
                  class="btn anime"
                  @click="redirectToOrganisation(organisation.path_acronym)"
                >
                  <img
                    :src="logoImage(organisation.logo)"
                    @error="$event.target.src = 'img/talentfind_logo1.png'"
                    class="image mr-2"
                  />
                  <strong>{{ organisation.organisation_name }}</strong>
                </button>
              </span>
            </CRow>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ListOrganisation",
  data() {
    return {
      selectedIndex: [],
    };
  },
  computed: {
    ...mapGetters(["getPublicListOrganisation"]),
  },
  methods: {
    ...mapActions(["fetchPublicListOrganisation"]),
    redirectToOrganisation(val) {
      this.$router.push({ path: `/${val}` });
    },
    logoImage(logo) {
      return logo
        ? "https://" + window.location.host + logo
        : "img/talentfind_logo1.png";
    },
  },
  mounted() {
    this.fetchPublicListOrganisation();
  },
};
</script>
<style scoped>
.container {
  width: 100%;
  display: inline-block;
  margin-top: 50px;
}

.container .card {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.5);
}
.container .card .layer {
  z-index: 1;
  position: absolute;
  top: calc(100% - 2px);
  height: 100%;
  width: 100%;
  left: 0;
  background: linear-gradient(to left, #ddd2d4, #e0c0c5);
  transition: 0.5s;
}

.container .card .content {
  z-index: 2;
  position: relative;
}

.container .card:hover .layer {
  top: 0;
}

.image {
  width: 100px;
  height: 50px;
  object-fit: contain;
}

.organisation-name {
  border: 1px solid #ddd2d4;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
}

.organisation-name:hover {
  background: white;
}
</style>
